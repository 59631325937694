import React from 'react'

import './ModuleCard.scss'

import logo from '../../images/test_heading_3.png'

export default (props) => {
    
    return(
        <div className="module-container">
            <div className="module-container__img">
                <img src={logo} alt="Logo" />
            </div>
            <div className="module-container__text">
                <h4>Header Text</h4>
                <p>Text Here</p>
                <p>More Text Here</p>
            </div>
        <div className="module-container__cta">

        </div>
    </div>
    )
}