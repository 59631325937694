import React from 'react'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle/PageTitle'
import ContentBlock from '../components/LandingContentBlock/ContentBlock'
import ModuleCard from '../components/ModuleCard/ModuleCard'

import './scss/online-course.scss'


export default () => (
    <Layout>
        <PageTitle title="Online Courses">
        We have a great selection of online follow along video courses that are designed for you if you don’t want, or need, individual tuition.
        </PageTitle>
        <ContentBlock>
        <div className="divider-container">
                <div className="divider-container__item">
                    <h2>Thinkific Courses</h2>
                    <p>You can buy an entire course on a maths subject, or a single module from a range of topics. The videos are clear and easy to understand, and you can follow along at your own pace.</p>
                </div>
                <div className="divider-container__item">
                    <div className="divider-container__item__img-container">
                    <img src="https://placedog.net/500/350" />
                    </div>
                
                </div>
            </div>
        </ContentBlock>
        <ContentBlock>
            <div className="container">
                <ModuleCard />
                <ModuleCard />
            </div>
        </ContentBlock>
    </Layout>
)